import { ThreeEvent } from "@react-three/fiber";
import { Color } from "three";
import { Roof, RoofState } from "../valueObjects/Roof";
import { RoofFace } from "../valueObjects/RoofFace";
import { RoofFaceState } from "../valueObjects/Stage";
import { CurrentMousePositionOnGroundPlane } from "../stores/CurrentMousePositionOnGroundPlane";
import { RoofEditorState } from "../stores/RoofEditStore";

export class RoofEditorDrawService {
  public static onMouseMove(
    event: ThreeEvent<MouseEvent>,
    previousState: CurrentMousePositionOnGroundPlane
  ): CurrentMousePositionOnGroundPlane {
    return {
      ...previousState,
      currentMousePosition: event.point,
    };
  }

  public static onClicked(
    event: ThreeEvent<MouseEvent>,
    previousState: RoofEditorState,
    accessToken: string
  ): RoofEditorState {
    const { roofs } = previousState;
    let activeRoof = RoofEditorDrawService.getActiveRoof(roofs);
    event.point.z = 0.05;

    if (activeRoof === undefined) {
      console.log("no active roof found, creating one");
      activeRoof = {
        id: undefined,
        name: "Roof " + roofs.length,
        state: RoofState.started,
        roofFaces: [],
        height: 3,
      };

      previousState.roofs.push(activeRoof);
    }

    const { roofFaces } = activeRoof;

    let activeRoofFace = RoofEditorDrawService.getStartedRoofFace(roofFaces);

    if (activeRoofFace === undefined) {
      console.log("Creating active roof face");
      activeRoofFace = new RoofFace(
        "RoofFace " + roofFaces.length,
        new Color().setHex(0xffffff * Math.random()),
        activeRoof,
        []
      );
      activeRoofFace.state = RoofFaceState.started;
      activeRoof.roofFaces.push(activeRoofFace);
    }

    if (
      activeRoofFace.points.length > 0 &&
      activeRoofFace.isCloseToFirst(event.point)
    ) {
      console.log("closing Roof Face");
      activeRoofFace.addPoint(activeRoofFace.points[0]);
      activeRoofFace.state = RoofFaceState.completed;
    } else {
      activeRoofFace.addPoint(event.point);
    }

    previousState.createOrUpdateInBackend(accessToken)
    return {
      ...previousState,
    };
  }

  private static getStartedRoofFace(
    roofFaces: RoofFace[]
  ): RoofFace | undefined {
    for (let i = 0; i < roofFaces.length; i++) {
      if (roofFaces[i].state === RoofFaceState.started) {
        return roofFaces[i];
      }
    }

    return undefined;
  }

  private static getActiveRoof(roofs: Roof[]): Roof | undefined {
    for (let i = 0; i < roofs.length; i++) {
      if (roofs[i].state === RoofState.started) {
        return roofs[i];
      }
    }

    return undefined;
  }
}
