import axios from "axios";

function AxiosClient() {
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": 'https://lelzec.de',
      "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization",
      "Access-Control-Allow-Credentials": true,
    },
  });
}

const unauthorizedAxiosClient = AxiosClient();

export const authorizedAxiosClient = (token: string) => {
  unauthorizedAxiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return unauthorizedAxiosClient;
}