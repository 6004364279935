import { useRoofEditorState } from "../stores/RoofEditStore";
import MurmurHash3 from "imurmurhash";

type StageProps = {
  stages: string[];
};

export function StagesTopMenu({ stages }: StageProps) {
  const stage = useRoofEditorState((state) => state.stage);
  const onClick = useRoofEditorState((state) => state.changeStage);
  const murmur = new MurmurHash3()
  return (
    <div className="stageContainer">
      {stages.map((tmpStage, i) => (
        <button
          key={murmur.hash(tmpStage).result()}
          className={tmpStage === stage ? "stage active" : "stage"}
          onClick={onClick}
          data-stage-name={tmpStage}
        >
          {tmpStage}
        </button>
      ))}
    </div>
  );
}
