import { useRef, useState } from "react";
import { saveAs } from 'file-saver';

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<string>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: () => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly name: string | undefined;
  readonly url: string | undefined;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();

  const download = async () => {
    try {
      preDownloading();
      const data  = await apiDefinition();
      setFileUrl(url);
      setFileName(getFileName());
      ref.current?.click();
      saveAs(new Blob([data], {type: 'text/plain'}), getFileName())
      postDownloading();
    } catch (error) {
      onError();
    }
  };

  return { download, ref, url, name };
};