import { House } from "../../components/House";
import { Roof } from "../../valueObjects/Roof";
import { Module } from "../../components/Module";
import { RoofOutline } from "../../components/RoofOutline";

export function ExtrudeStage({
  roofs,
  stage,
  token,
}: {
  roofs: Roof[];
  stage: string;
  token: string;
}) {

  if (stage !== "ExtrudeStage") {
    return <></>;
  }
  return (
    <group>
      {roofs.map((roof) => (
        <House roof={roof} />
      ))}
      {roofs.map((roofs) =>
        roofs.roofFaces.map((roofFace) => {
          return (
            <>
              {roofFace.getSides().map((side, i) => (
                <RoofOutline side={side} token={token} />
              ))}
              {roofFace.modules.map((module) => (
                <Module
                  topMin={module.topMin}
                  topMax={module.topMax}
                  bottomMax={module.bottomMax}
                  bottomMin={module.bottomMin}
                  roofFace={roofFace}
                  moduleHeight={0.03}
                />
              ))}
            </>
          );
        })
      )}
    </group>
  );
}
