import { RoofFace } from "./RoofFace";

export enum RoofFaceState {
  notStarted,
  started,
  completed,
}

export class StageState {
  roofFaces: RoofFace[];

  constructor(roofFaces: RoofFace[]) {
    this.roofFaces = roofFaces;
  }
}

export interface Stage {
  name: string;
  stageState: StageState;
  active: boolean;

  enableStage(stageState: StageState): void;
  disableStage(): void;
  getResults(): StageState;
  isActive(): boolean;
}
