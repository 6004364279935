import { Vector3 } from "three";

export function generateTriangulatedSides(pointA: Vector3, pointB: Vector3, bottom: number) {
    const NUM_TRIANGLES = 5;
    const allTriangles: number[] = [];
  
    const xDiff = pointA.x - pointB.x;
    const yDiff = pointA.y - pointB.y;
    const zTopDiff = pointA.z - pointB.z;
  
    const brokenDownX = xDiff / NUM_TRIANGLES;
    const brokenDownY = yDiff / NUM_TRIANGLES;
    const brokenDownZTop = zTopDiff / NUM_TRIANGLES;
  
    for (let j = 1; j <= NUM_TRIANGLES; j++) {
      const backwardX = pointB.x + brokenDownX * (j - 1);
      const backwardY = pointB.y + brokenDownY * (j - 1);
      const backwardZTop = pointB.z + brokenDownZTop * (j - 1);
  
      const forwardX = pointB.x + brokenDownX * j;
      const forwardY = pointB.y + brokenDownY * j;
      const forwardZTop = pointB.z + brokenDownZTop * j;
  
      allTriangles.push(
        backwardX,
        backwardY,
        backwardZTop,
        backwardX,
        backwardY,
        bottom,
        forwardX,
        forwardY,
        forwardZTop
      );
      allTriangles.push(
        backwardX,
        backwardY,
        bottom,
        forwardX,
        forwardY,
        bottom,
        forwardX,
        forwardY,
        forwardZTop
      );
    }
    return allTriangles;
  }