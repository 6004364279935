import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import img from "../images/map_zoom_21.png";
import { RepeatWrapping } from "three";
import { useRoofEditorState } from "../stores/RoofEditStore";
import { useCurrentMousePositionOnGroundPlaneStore } from "../stores/CurrentMousePositionOnGroundPlane";

export function GroundPlane({token}: {token: string}) {
  const texture = useLoader(THREE.TextureLoader, img);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping;

  const onClick = useRoofEditorState((state) => state.onClick);
  const onMouseMoved = useCurrentMousePositionOnGroundPlaneStore(
    (state) => state.onMouseMoved
  );

  return (
    <mesh onClick={(e) => onClick(e, token)} onPointerMove={onMouseMoved}>
      <planeGeometry attach="geometry" args={[31.7942878825, 31.7942878825]} />
      <meshBasicMaterial attach="material" map={texture} toneMapped={false} />
    </mesh>
  );
}
