import { useRoofEditorState } from "../../stores/RoofEditStore";
import * as THREE from "three";
import { Line, QuadraticBezierLine, Text } from "@react-three/drei";
import { Vector3 } from "three";
import { Side } from "../../valueObjects/Side";
import { useCurrentMousePositionOnGroundPlaneStore } from "../../stores/CurrentMousePositionOnGroundPlane";
import MurmurHash3 from "imurmurhash";

export function DrawStage() {
  const { roofs, stage } = useRoofEditorState((state) => ({
    roofs: state.roofs,
    stage: state.stage,
  }));
  const currentMousePosition = useCurrentMousePositionOnGroundPlaneStore(
    (state) => state.currentMousePosition
  );

  if (stage !== "DrawStage" && stage !==  "ModuleAllocation") {
    return <></>;
  }
  const murmur = new MurmurHash3()

  return (
    <>
      {
        // Draw Points
        roofs.map((roof) =>
          roof.roofFaces.map((roofFace) =>
            roofFace.points.map((point, i) => (
              <mesh
                position={[point.x, point.y, 0]}
              >
                <sphereGeometry attach="geometry" args={[0.05]} />
                <meshBasicMaterial attach="material" color="red" />
              </mesh>
            ))
          )
        )
      }
      {
        // Draw lines
        roofs.map((roof) =>
          roof.roofFaces.map((roofFace) =>
            roofFace.getSides().map((side) => {
              const diffX = side.pointA.x - side.pointB.x;
              const diffY = side.pointA.y - side.pointB.y;
              const diffZ = side.pointA.z - side.pointB.z;

              const middlePoint = new Vector3(
                side.pointA.x - diffX / 2,
                side.pointA.y - diffY / 2,
                side.pointA.z - diffZ / 2
              );
              return (
                <group>
                  <Line
                    points={[
                      side.pointA.clone().setZ(0.1),
                      side.pointB.clone().setZ(0.1),
                    ]}
                    color="blue"
                    lineWidth={2}
                  />
                  <Text
                    color="red"
                    position={middlePoint.setZ(0.1)}
                    fontSize={0.25}
                  >
                    {side.length.toFixed(2)}m
                  </Text>
                </group>
              );
            })
          )
        )
      }
      {roofs.map((roof) =>
        roof.roofFaces.map((roofFace) => {
          const angles: { angle: number; pointA: Vector3; pointB: Vector3 }[] =
            [];
          const sides = roofFace.getSides();
          let previousSide: Side = sides[0];

          for (let i = 1; i < sides.length; i++) {
            const side = sides[i];
            var a = new THREE.Vector3();
            a.copy(previousSide.pointA).sub(previousSide.pointB);

            var b = new THREE.Vector3();
            b.copy(side.pointA).sub(side.pointB);
            const angle = (a.angleTo(b) * 180) / Math.PI;

            const sideXDiff = side.pointA.x - side.pointB.x;
            const sideYDiff = side.pointA.y - side.pointB.y;
            const sideZDiff = side.pointA.z - side.pointB.z;

            const previousSideXDiff =
              previousSide.pointB.x - previousSide.pointA.x;
            const previousSideYDiff =
              previousSide.pointB.y - previousSide.pointA.y;
            const previousSideZDiff =
              previousSide.pointB.z - previousSide.pointA.z;

            angles.push({
              angle,
              pointA: new THREE.Vector3(
                previousSide.pointB.x - previousSideXDiff / 8,
                previousSide.pointB.y - previousSideYDiff / 8,
                previousSide.pointB.z - previousSideZDiff / 8
              ),
              pointB: new THREE.Vector3(
                side.pointA.x - sideXDiff / 8,
                side.pointA.y - sideYDiff / 8,
                side.pointA.z - sideZDiff / 8
              ),
            });

            previousSide = side;
          }

          return angles.map(({ angle, pointA, pointB }) => {
            const xDiff = pointB.x - pointA.x;
            const yDiff = pointB.y - pointA.y;

            const middlePoint = new THREE.Vector3(
              pointA.x + xDiff / 2,
              pointA.y + yDiff / 2,
              0
            );
            return (
              <>
                <QuadraticBezierLine
                  start={pointB.clone().setZ(0.1)}
                  end={pointA.clone().setZ(0.1)}
                  color="black"
                  lineWidth={2}
                  segments={10}
                />
                <Text color="green" position={middlePoint} fontSize={0.08}>
                  {angle.toFixed(0)}°
                </Text>

                <mesh position={pointA.clone().setZ(0.1)}>
                  <sphereGeometry attach="geometry" args={[0.01]} />
                  <meshBasicMaterial attach="material" color="hotpink" />
                </mesh>
                <mesh position={pointB.clone().setZ(0.1)}>
                  <sphereGeometry attach="geometry" args={[0.01]} />
                  <meshBasicMaterial attach="material" color="hotpink" />
                </mesh>
              </>
            );
          });
        })
      )}

      <mesh position={[currentMousePosition.x, currentMousePosition.y, 0]}>
        <sphereGeometry attach="geometry" args={[0.05]} />
        <meshBasicMaterial attach="material" color="blue" />
      </mesh>
    </>
  );
}
