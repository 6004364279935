import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Project } from "../valueObjects/Project";

type ProjectComponentProps = {
    project: Project;
};

const ProjectWrapper = styled.div`
    display: flex;
    flex-flow: row no-wrap;
    justify-content: space-between;
    background-color: grey;
    margin: 0.25%;
    border-radius: 4px;
    padding-right: 5%;
    padding-left: 5%;
`
const FontAwesomeIconVerticalAlignedMiddle = styled(FontAwesomeIcon)`
    vertical-align: middle;
`

const LinkOnBox = styled(Link)`
    text-decoration: none;
    color: black;
`

export function ProjectComponent({project}: ProjectComponentProps) {
    return (
        <>
            <LinkOnBox to={`/roof/${project.name}/roofEditor`}>
                <ProjectWrapper>
                    <h3>{project.name}</h3>
                    <h6>{project.status}</h6>
                    <FontAwesomeIconVerticalAlignedMiddle icon={faArrowRight} size="2x"/>
                </ProjectWrapper>
            </LinkOnBox>
        </>
    );
}