import { Color, Vector3 } from "three";
import { ModulePlacement } from "./ModulePlacement";
import { Roof } from "./Roof";
import { Side } from "./Side";
import { RoofFaceState } from "./Stage";

function placeAtDistanceAndAngle(
  point: Vector3,
  angleInRadians: number,
  distance: number
): Vector3 {
  const x = point.x + distance * Math.cos(angleInRadians);
  const y = point.y + distance * Math.sin(angleInRadians);
  const z = 0;
  return new Vector3(x, y, z);
}

function angleBetweenToVectors(a: Vector3, b: Vector3): number {
  var dx = a.x - b.x;
  var dy = a.y - b.y;
  return Math.atan2(dy, dx);
}

function angleBetweenToSides(a: Side, b: Side): number {
  const aSub = a.pointA.sub(a.pointB);
  const bSub = b.pointA.sub(b.pointB);

  return angleBetweenToVectors(aSub, bSub);
}

export class RoofFace {
  private name: string;
  roof: Roof;
  // Make points private so it's protected from modification outside of addPoint
  points: Vector3[] = [];
  state: RoofFaceState = RoofFaceState.started;
  sides: Side[] = [];
  minDistance: number = 0;
  maxDistance: number = 0;
  height: number = 0;
  eave: Side | undefined;
  color: Color;
  modules: ModulePlacement[] = [];

  public constructor(name: string, color: Color, roof: Roof, modules: ModulePlacement[]) {
    this.name = name;
    this.color = color;
    this.roof = roof;
    this.modules = modules;
  }

  public addPoint(point: Vector3): void {
    this.points.push(point);
    this.createSides();
  }

  public isCloseToFirst(compare: Vector3): boolean {
    return this.points[0].distanceTo(compare) < 0.5;
  }

  public getFourLongestSides(): Side[] {
    return this.sides
      .sort((a: Side, b: Side) => b.length - a.length)
      .splice(0, 4);
  }

  public getSides(): Side[] {
    return this.sides;
  }

  public getName(): string {
    return this.name;
  }

  public toSerializable(): {
    sides: {
      pointA: { x: number; y: number; z: number };
      pointB: { x: number; y: number; z: number };
    }[];
    eave: {
      pointA: { x: number; y: number; z: number };
      pointB: { x: number; y: number; z: number };
    } | undefined;
  } {
    return {
      sides: this.sides.map((side) => ({
        pointA: { x: side.pointA.x, y: side.pointA.y, z: side.pointA.z },
        pointB: { x: side.pointB.x, y: side.pointB.y, z: side.pointB.z },
      })),
      eave: this.eave !== undefined ? {pointA: {x: this.eave?.pointA.x, y: this.eave?.pointA.y, z: this.eave?.pointA.z}, pointB: {x: this.eave?.pointB.x, y: this.eave?.pointB.y, z: this.eave?.pointB.z}}: undefined
    };
  }

  public triggerHeightOrEaveUpdate(): void {
    if (this.eave === undefined) {
      this.setSidesOnRoofHeight();
      return;
    }
    console.log(this.sides)
    console.log(this.eave)
    this.generateHeightMapOnSides(this.eave, this.height);
  }

  private generateHeightMapOnSides(inputDesignatedSide: Side, height: number): void {
    const perpendicularToDesignatedSide = this.sides.find((tmpSide) => tmpSide.pointB.equals(inputDesignatedSide.pointA))
    if(perpendicularToDesignatedSide === undefined) {
      console.error('Couldn\'t find perpendicular to designated side')
      return;
    }
    const anglePerp = angleBetweenToVectors(perpendicularToDesignatedSide.pointA, perpendicularToDesignatedSide.pointB)


    let straightEave: Side;
    if(perpendicularToDesignatedSide.getDistanceToPoint2D(inputDesignatedSide.pointA) > perpendicularToDesignatedSide.getDistanceToPoint2D(inputDesignatedSide.pointB)) {
      const b = placeAtDistanceAndAngle(inputDesignatedSide.pointA, anglePerp+ 1.5708, inputDesignatedSide.length);
      const a = inputDesignatedSide.pointA;
      straightEave = new Side(a, b, a.distanceTo(b), this);
    } else {      
      const b = placeAtDistanceAndAngle(inputDesignatedSide.pointB, anglePerp+ 1.5708, inputDesignatedSide.length);
      const a = inputDesignatedSide.pointB;
      straightEave = new Side(a, b, a.distanceTo(b), this);
    }

    const designatedSide = Side.cloneAndExtendLine(inputDesignatedSide, 32, 32);
    
    const distances: number[] = [];

    this.sides.forEach((side) => {
      distances.push(designatedSide.getDistanceToPoint2D(side.pointA));
      distances.push(designatedSide.getDistanceToPoint2D(side.pointB));
    });

    this.minDistance = Math.min.apply(null, distances);
    this.maxDistance = Math.max.apply(null, distances);

    this.sides.forEach((side) => {
      const distanceA = designatedSide.getDistanceToPoint2D(side.pointA);
      const distanceB = designatedSide.getDistanceToPoint2D(side.pointB);

      const normalizedDistanceA = RoofFace.calcNormalizeNumber(
        distanceA,
        this.minDistance,
        this.maxDistance
      );
      const normalizedDistanceB = RoofFace.calcNormalizeNumber(
        distanceB,
        this.minDistance,
        this.maxDistance
      );

      side.pointA.setZ(height * normalizedDistanceA + this.roof.height);
      side.pointB.setZ(height * normalizedDistanceB + this.roof.height);
    });
  }

  private setSidesOnRoofHeight(): void {
    this.getSides().forEach((side) => {
      side.pointA.setZ(this.roof.height);
      side.pointB.setZ(this.roof.height);
    });
  }

  public getHeightForPoint(point: Vector3): number {
    if (this.eave === undefined) {
      return this.roof.height;
    }

    return (
      this.height *
        RoofFace.calcNormalizeNumber(
          this.eave.getDistanceToPoint2D(point),
          this.minDistance,
          this.maxDistance
        ) +
      this.roof.height
    );
  }

  private static calcNormalizeNumber(value: number, min: number, max: number) {
    const tmp = (value - min) / (max - min);
    return tmp >= 1 ? 1 : tmp;
  }

  private createSides(): void {
    const sides: Side[] = [];

    let previousPoint = this.points.at(0);
    if (previousPoint === undefined) {
      return;
    }

    for (let i = 1; i < this.points.length; i++) {
      const point: Vector3 | undefined = this.points.at(i);
      if (point === undefined) {
        continue;
      }

      sides.push(
        new Side(previousPoint, point, previousPoint.distanceTo(point), this)
      );

      previousPoint = point;
    }

    this.sides = sides;
  }
}
