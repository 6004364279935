import { ThreeEvent } from "@react-three/fiber";
import { Vector3 } from "three";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { RoofEditorDrawService } from "../services/RoofEditorDrawService";

export interface CurrentMousePositionOnGroundPlane {
  currentMousePosition: Vector3;
  onMouseMoved: (event: ThreeEvent<MouseEvent>) => void;
}

export const useCurrentMousePositionOnGroundPlaneStore =
  create<CurrentMousePositionOnGroundPlane>(
    devtools((set) => ({
      currentMousePosition: new Vector3(0, 0, 0),
      onMouseMoved: (event: ThreeEvent<MouseEvent>) =>
        set((previousState) =>
          RoofEditorDrawService.onMouseMove(event, previousState)
        ),
    }))
  );
