import { ThreeEvent } from "@react-three/fiber";
import { SyntheticEvent } from "react";
import { Color, Vector3 } from "three";
import create from "zustand";
import { RoofEditorDrawService } from "../services/RoofEditorDrawService";
import { Roof, RoofState } from "../valueObjects/Roof";
import { devtools } from "zustand/middleware";
import { Side } from "../valueObjects/Side";
import { RoofFace } from "../valueObjects/RoofFace";
import { authorizedAxiosClient } from "./axiosClient";
import { ModulePlacement } from "../valueObjects/ModulePlacement";
import { isArray } from "lodash";
import { RoofFaceState } from "../valueObjects/Stage";

type Address = {
  address: string;
  longitude: number;
  latitude: number;
};

export interface RoofEditorState {
  address: Address;
  stage: string;
  roofs: Roof[];
  currentMousePosition: Vector3;
  modules: ModulePlacement[];
  onClick: (event: ThreeEvent<MouseEvent>, token: string) => void;
  changeStage: (event: any) => void;
  changeRoofFaceHeight: (
    roofFace: RoofFace,
    height: number,
    token: string
  ) => void;
  changeRoofHeight: (roofFace: Roof, height: number, token: string) => void;
  changeRoofFaceEave: (roofFaceName: string, eave: Side, token: string) => void;
  createOrUpdateInBackend: (token: string) => Promise<any>;
  setRoofId: (token: string, roofId: string) => void;
}

export const useRoofEditorState = create<RoofEditorState>(
  devtools((set, get) => ({
    stage: "DrawStage",
    address: {
      address: "An der Burgenwand 22, 72336 Balingen, Deutschland",
      longitude: 48.2772776,
      latitude: 8.8645998,
    },
    roofs: [],
    modules: [],
    currentMousePosition: new Vector3(0, 0, 0),
    onClick: (event: ThreeEvent<MouseEvent>, accessToken: string) =>
      set((previousState) =>
        previousState.stage === "DrawStage"
          ? RoofEditorDrawService.onClicked(event, previousState, accessToken)
          : previousState
      ),
    changeStage: (event: SyntheticEvent) =>
      set((previousState) => {
        previousState.roofs.forEach((roof) =>
          roof.roofFaces.forEach((roofFace) =>
            roofFace.triggerHeightOrEaveUpdate()
          )
        );

        const stage = event.currentTarget.getAttribute("data-stage-name");
        return {
          ...previousState,
          stage: stage ?? previousState.stage,
        };
      }),
    changeRoofFaceHeight: (
      roofFace: RoofFace,
      height: number,
      accessToken?: string
    ) =>
      set((previousState) => {
        if (accessToken === undefined) {
          throw new Error("No access token provided");
        }
        console.log(roofFace.getName(), "height set to", height);
        roofFace.height = height;
        roofFace.triggerHeightOrEaveUpdate();
        previousState.createOrUpdateInBackend(accessToken);

        return { ...previousState };
      }),
    changeRoofHeight: (roof: Roof, height: number, token: string) =>
      set((previousState) => {
        console.log(roof.name, "height set to", height);
        roof.height = height;
        roof.roofFaces.forEach((roofFace) =>
          roofFace.triggerHeightOrEaveUpdate()
        );

        previousState.createOrUpdateInBackend(token);
        return { ...previousState };
      }),
    changeRoofFaceEave: (
      roofFaceName: string,
      eave: Side,
      accessToken?: string
    ) =>
      set((previousState) => {
        if (accessToken === undefined) {
          throw new Error("No access token provided");
        }

        console.log(roofFaceName, "Eave selected");
        previousState.roofs.forEach((roof) => {
          roof.roofFaces.forEach((roofFace) => {
            if (roofFace.getName() === roofFaceName) {
              roofFace.eave = eave;
              roofFace.triggerHeightOrEaveUpdate();
              previousState.createOrUpdateInBackend(accessToken);
            }
          });
        });

        return { ...previousState };
      }),

    createOrUpdateInBackend: async (accessToken) => {
      const { roofs, address } = get();
      roofs.map(async (roof) => {
        if (roof.id === undefined) {
          console.log("Dach", roof);
          return authorizedAxiosClient(accessToken)
            .post("/roofs", {
              roofFaces: roof.roofFaces.map((roofFace) =>
                roofFace.toSerializable()
              ),
              coordinates: {
                latitude: address.latitude,
                longitude: address.longitude,
              },
            })
            .then((response) => {
              if (response.status === 201) {
                console.log("Roof Id: ", response.data.roofId);
                set((previousState) => {
                  const activeRoof = previousState.roofs.find(
                    (tmpRoof) => roof.name === tmpRoof.name
                  );
                  if (activeRoof === undefined) {
                    return previousState;
                  }

                  activeRoof.id = response.data.roofId;
                  return previousState;
                });
              }
            });
        } else {
          return authorizedAxiosClient(accessToken)
            .patch<Roof>("/roofs/" + roof.id, {
              roofFaces: roof.roofFaces.map((roofFace) =>
                roofFace.toSerializable()
              ),
              coordinates: {
                latitude: address.latitude,
                longitude: address.longitude,
              },
            })
            .then((response) => {
              set((previousState) => {
                const activeRoof = previousState.roofs.find(
                  (tmpRoof) => tmpRoof.id === roof.id
                );
                if (activeRoof === undefined) {
                  return;
                }
                for (let i = 0; i < response.data.roofFaces.length; i++) {
                  const roofFace = response.data.roofFaces[i];
                  const oldRoofFace = activeRoof.roofFaces[i];

                  if (isArray(roofFace.modules)) {
                    // Just update the modules for now
                    oldRoofFace.modules = roofFace.modules;
                  }
                }

                return {
                  ...previousState,
                };
              });
            });
        }
      });
    },
    setRoofId: async (token, roofId) => {
      const { data: roof } = await authorizedAxiosClient(token).get<
        Roof & { roofId: string }
      >("/roofs/" + roofId);
      roof.height = 0;
      console.log(roof.roofFaces);
      const tmpRoof: Roof = {
        name: "Roof 1",
        id: roof.roofId,
        state: RoofState.started,
        roofFaces: [],
        height: 5,
      };

      tmpRoof.roofFaces = roof.roofFaces.map((roofFace, i) => {
        const newRoofFace = new RoofFace(
          "RoofFace " + i,
          new Color(0xffffff * Math.random()),
          tmpRoof,
          roofFace.modules
        );
        roofFace.sides.forEach((side) => {
          const vectorA = new Vector3(
            side.pointA.x,
            side.pointA.y,
            side.pointA.z
          );
          const vectorB = new Vector3(
            side.pointB.x,
            side.pointB.y,
            side.pointB.z
          );
          if (vectorA.equals(vectorB) !== true) {
            newRoofFace.addPoint(vectorA);
            newRoofFace.addPoint(vectorB);
          }
        });
        if (roofFace.eave !== undefined) {
          const pointA = new Vector3(
            roofFace.eave.pointA.x,
            roofFace.eave.pointA.y,
            roofFace.eave.pointA.z
          );
          const pointB = new Vector3(
            roofFace.eave.pointB.x,
            roofFace.eave.pointB.y,
            roofFace.eave.pointB.z
          );
          newRoofFace.eave = new Side(
            pointA,
            pointB,
            pointA.distanceTo(pointB),
            newRoofFace
          );
        }
        return newRoofFace;
      });

      set((previousState) => {
        return {
          stage: "DrawStage",
          address: {
            address: "An der Burgenwand 22, 72336 Balingen, Deutschland",
            longitude: 48.2772776,
            latitude: 8.8645998,
          },
          roofs: [tmpRoof],
          modules: [],
          currentMousePosition: new Vector3(0, 0, 0),
        };
      });
    },
  }))
);
