import { ChangeEventHandler } from "react";
import { Roof, RoofState } from "../valueObjects/Roof";
import { RoofFaceState } from "../valueObjects/Stage";
import { DownloadRoofDesignButton } from "./DownloadRoofDesignButton";

function FloatInput({
  label,
  state,
  onChange,
}: {
  label: string;
  state: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <div>
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        value={state.toFixed(2)}
        onChange={onChange}
        style={{ float: "right" }}
      />
    </div>
  );
}

export function RightHandMenu({
  roofs,
  stage,
  setHeightForRoof,
  setHeightForRoofFace,
}: {
  roofs: Roof[];
  stage: string;
  setHeightForRoofFace: Function;
  setHeightForRoof: Function;
}) {
  return (
    <div className="rightHandMenu">
      {roofs.map((roof) => {
        return (
          <div className="roof" key={'RightHandRoofMenu'+roof.name}>
            <DownloadRoofDesignButton roofId={roof.id ?? ''} />
            {roof.name}:{" "}
            {roof.state === RoofState.completed
              ? "abgeschlossen"
              : "nicht abgeschlossen"}
            {stage === "ExtrudeStage" ? (
              <input
                value={roof.height.toFixed(2)}
                onChange={(event) =>
                  setHeightForRoof(roof, parseFloat(event.target.value))
                }
              />
            ) : (
              <></>
            )}
            <div className="roofFaces">
              {roof.roofFaces.map((roofFace) => {
                return (
                  <div className="roofFace" key={'RightHandRoofMenu'+roofFace.getName()}>
                    {roofFace.getName()}:{" "}
                    {roofFace.state === RoofFaceState.completed
                      ? "abgeschlossen"
                      : "nicht abgeschlossen"}
                    {stage === "ExtrudeStage" &&
                    roofFace.state === RoofFaceState.completed ? (
                      <FloatInput
                        label="Ridge Purlin: "
                        onChange={(event) =>
                          setHeightForRoofFace(
                            roofFace,
                            parseFloat(event.target.value)
                          )
                        }
                        state={roofFace.height}
                      />
                    ) : (
                      <></>
                    )}
                    <ul>
                      {roofFace.points.map((point, i) => (
                        <li
                          key={"RightHandMenuPoint" + point.x / i + point.y / i}
                        >
                          Point {i} ({point.x.toFixed(2)}, {point.y.toFixed(2)})
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
