import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { GroundPlane } from "../components/GroundPlane";
import { CameraControls } from "../camera-controls";
import { PerspectiveCamera, Stats } from "@react-three/drei";
import { DirectionalLight } from "three";
import { DrawStage } from "./roofEditorStages/DrawStage";
import { StagesTopMenu } from "../components/StagesTopMenu";
import { ExtrudeStage } from "./roofEditorStages/ExtrudeStage";
import { RightHandMenu } from "../components/RightHandMenu";
import { useRoofEditorState } from "../stores/RoofEditStore";
import _ from "lodash";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { RoofFace } from "../valueObjects/RoofFace";
import { useParams } from "react-router-dom";

export const RoofEditor = withAuthenticationRequired(function RoofEditor() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  let { id } = useParams();
  const setRoofId = useRoofEditorState(
    (state) => state.setRoofId
  );

  useEffect(() => {
    const getToken = async () => setToken(await getAccessTokenSilently());
    getToken().then(() => {
      if(id !== undefined && token !== '') {
        setRoofId(token, id);
      }
    });
  }, [setRoofId, id, getAccessTokenSilently, setToken, token]);


  const cameraControls = useRef<CameraControls | null>(null);
  const dirLight = useMemo(() => {
    const light = new DirectionalLight("white");
    light.castShadow = true;
    //Set up shadow properties for the light
    light.shadow.mapSize.width = 10240; // default
    light.shadow.mapSize.height = 10240; // default
    light.shadow.camera.near = 0.1; // default
    light.shadow.camera.far = 5000; // default
    light.shadow.camera.top = -100; // default
    light.shadow.camera.right = 100; // default
    light.shadow.camera.left = -100; // default
    light.shadow.camera.bottom = 100; // default
    return light;
  }, []);

  const roofs = useRoofEditorState(
    (state) => state.roofs,
    (oldRoofs, newRoofs) => !_.isEqual(oldRoofs, newRoofs)
  );
  const stage = useRoofEditorState((state) => state.stage);

  const setHeightForRoofFace = useRoofEditorState(
    (state) => state.changeRoofFaceHeight
  );
  const setHeightForRoof = useRoofEditorState(
    (state) => state.changeRoofHeight
  );

  return (
    <>
      <StagesTopMenu stages={["DrawStage", "ExtrudeStage"]} />
      <RightHandMenu
        roofs={roofs}
        stage={stage}
        setHeightForRoofFace={(roofFace: RoofFace, height: number) =>
          setHeightForRoofFace(roofFace, height, token)
        }
        setHeightForRoof={setHeightForRoof}
      />
      <Canvas>
        <Stats />
        <PerspectiveCamera makeDefault position={[0, 0, 250]} />
        <ambientLight intensity={0.2} />
        <primitive object={dirLight} position={[30, 0, 30]} />
        <primitive object={dirLight.target} position={[0, 0, 0]} />

        <CameraControls ref={cameraControls} />

        <Suspense fallback={null}>
          <DrawStage />
          <ExtrudeStage roofs={roofs} stage={stage} token={token} />
          <GroundPlane token={token} />
        </Suspense>
      </Canvas>
    </>
  );
});
