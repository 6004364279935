import create from "zustand";
import { devtools } from "zustand/middleware";
import { Project } from "../valueObjects/Project";
import { authorizedAxiosClient } from "./axiosClient";

export interface Projects {
  projects: Project[];
  load: (accessToken: string) => void;
}

export const useProjectStore = create<Projects>(
  devtools((set, get) => ({
    projects: [],
    load: async (accessToken: string) => {
      const response = await authorizedAxiosClient(accessToken).get<Project[]>("/roofs");
      set((previousState) => {
        return {
          ...previousState,
          projects: response.data,
        };
      });
    },
  }))
);
