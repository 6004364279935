import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect } from "react";
import styled from "styled-components";
import { ProjectComponent } from "../components/ProjectComponent";
import { useProjectStore } from "../stores/ProjectStore";

const ProjectsWrapper = styled.div`
  width: 80%;
  margin-left: 10%;
`

export const ProjectPage = withAuthenticationRequired(function ProjectPage() {
  const { getAccessTokenSilently } = useAuth0();
  const { projects, load } = useProjectStore();

  useEffect(() => {
    const generateTokenAndLoadProjects = async () => {
      const token = await getAccessTokenSilently();
      load(token);
    };
    generateTokenAndLoadProjects();
  }, [getAccessTokenSilently, load]);
  return (
    <ProjectsWrapper>
      <h1>Projects</h1>
      <div>
        {projects.map((project) => (
          <ProjectComponent project={project} />
        ))}
      </div>
    </ProjectsWrapper>
  );
});
