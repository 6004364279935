import { RoofFace } from "./RoofFace";

export enum RoofState {
  notStarted,
  started,
  completed,
}

export interface Roof {
  name: string;
  id: string | undefined;
  state: RoofState;
  roofFaces: RoofFace[];
  height: number;
}
