import { Line } from "@react-three/drei";
import MurmurHash3 from "imurmurhash";
import { Color } from "three";
import { Side } from "../valueObjects/Side";
import { useRoofEditorState } from "../stores/RoofEditStore";

interface RoofOutlineProps {
  side: Side;
  token: string;
}

export function RoofOutline({ side, token }: RoofOutlineProps) {
  const changeRoofFaceEave = useRoofEditorState(
    (state) => state.changeRoofFaceEave
  );
  const murmur = new MurmurHash3();

  const startingPoint = side.pointA.clone().setZ(side.roofFace.roof.height);
  const endPoint = side.pointB.clone().setZ(side.roofFace.roof.height);
  return (
    <Line
      points={[startingPoint, endPoint]}
      color={
        side.roofFace.eave === side
          ? new Color(side.roofFace.color.getHex() * 0.3)
          : side.roofFace.color
      }
      lineWidth={8}
      onClick={(event) => changeRoofFaceEave(side.roofFace.getName(), side, token)}
      key={murmur
        .hash(
          startingPoint.x +
            ";" +
            startingPoint.y +
            ";" +
            startingPoint.z +
            "|" +
            endPoint.x +
            ";" +
            endPoint.y +
            ";" +
            endPoint.z
        )
        .result()}
    />
  );
}
