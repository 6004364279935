import poly2tri from "poly2tri";
import { DoubleSide, Vector2, Vector3 } from "three";
import { Roof } from "../valueObjects/Roof";
import { RoofFaceState } from "../valueObjects/Stage";
import MurmurHash3 from "imurmurhash";
import { generateTriangulatedSides } from "../services/triangulationService";

const Z_BOTTOM = 0;

export function generateTriangles(roof: Roof): Float32Array[] {
  const triangles: Float32Array[] = [];

  roof.roofFaces.forEach((roofFace) => {
    if (roofFace.state !== RoofFaceState.completed) {
      return;
    }

    const allTriangles: number[] = [];

    // Build side triangles
    roofFace.getSides().forEach((side) => {
      allTriangles.push(...generateTriangulatedSides(side.pointA, side.pointB, Z_BOTTOM))
    });

    const coordinates = roofFace.points.map(
      (coord: Vector3) => new Vector2(coord.x, coord.y)
    );

    coordinates.pop();
    var roof = new poly2tri.SweepContext(
      coordinates.map((value: Vector2) => new poly2tri.Point(value.x, value.y))
    );
    roof.triangulate();

    roof.getTriangles().forEach((triangle: poly2tri.Triangle) => {
      triangle.getPoints().forEach((value: poly2tri.IPointLike) => {
        // Add function to calculate Z_TOP for specific coordinate
        allTriangles.push(
          value.x,
          value.y,
          roofFace.getHeightForPoint(new Vector3(value.x, value.y, 0))
        );
      });
    });

    roof.getTriangles().forEach((triangle: poly2tri.Triangle) => {
      triangle.getPoints().forEach((value: poly2tri.IPointLike) => {
        allTriangles.push(value.x, value.y, Z_BOTTOM);
      });
    });

    triangles.push(new Float32Array(allTriangles));
  });

  return triangles;
}


export function House({ roof }: { roof: Roof }) {
  const murmur = new MurmurHash3()
  return (
    <group>
      {generateTriangles(roof).map((triangles, i) => (
        <mesh key={murmur.hash(triangles.toString()).result()}>
          <bufferGeometry
            attach="geometry"
            onUpdate={(self) => self.computeVertexNormals()}
          >
            <bufferAttribute
              attach={"attributes-position"}
              array={triangles}
              itemSize={3}
              count={triangles.length / 3}
            />
          </bufferGeometry>
          <meshPhongMaterial
            color={0xbedce1}
            attach="material"
            side={DoubleSide}
          />
        </mesh>
      ))}
    </group>
  );
}
