import React, { useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { SpinnerButton, ButtonState } from "./SpinnerButton";
import { useDownloadFile } from "../services/useDownloadFile";
import { authorizedAxiosClient } from "../stores/axiosClient";
import { useAuth0 } from "@auth0/auth0-react";

export const DownloadRoofDesignButton: React.FC<{roofId: string}> = (props: {roofId: string}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [buttonState, setButtonState] = useState<ButtonState>(
    ButtonState.Primary
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const preDownloading = () => setButtonState(ButtonState.Loading);
  const postDownloading = () => setButtonState(ButtonState.Primary);

  const onErrorDownloadFile = () => {
    setButtonState(ButtonState.Primary);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getFileName = () => {
    return "RoofDesign.dxf";
  };

  const downloadRoofDesign = async () => {
    return authorizedAxiosClient(await getAccessTokenSilently()).post<{roofId: string, dxf: string}>(
      `/roofs/${props.roofId}/dxf`
    ).then((response) => response.data.dxf);
  };

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: downloadRoofDesign,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <Container className="mt-5">
      <Alert variant="danger" show={showAlert}>
        Something went wrong. Please try again!
      </Alert>
      <a href={url} download={name} className="hidden" ref={ref} />
      <SpinnerButton label="Download" buttonState={buttonState} onClick={download} />
    </Container>
  );
};