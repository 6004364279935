import { Line3, Vector3 } from "three";
import { RoofFace } from "./RoofFace";

export class Side {
  pointA: Vector3;
  pointB: Vector3;
  length: number;
  roofFace: RoofFace;

  constructor(
    pointA: Vector3,
    pointB: Vector3,
    length: number,
    roofFace: RoofFace
  ) {
    this.pointA = pointA;
    this.pointB = pointB;
    this.length = length;
    this.roofFace = roofFace;
  }

  public getDistanceToPoint2D(point: Vector3): number {
    const target = point.clone().setZ(0);
    const line = new Line3(
      this.pointA.clone().setZ(0),
      this.pointB.clone().setZ(0)
    );

    return line
      .closestPointToPoint(target, false, new Vector3())
      .distanceTo(target);
  }

  public static cloneAndExtendLine(
    side: Side,
    distancePointA: number,
    distancePointB: number
  ): Side {
    const pointA = Side.extendLineByLength(
      side.pointA,
      side.pointB,
      distancePointA
    );
    const pointB = Side.extendLineByLength(
      side.pointB,
      side.pointA,
      distancePointB
    );

    return new Side(pointA, pointB, pointA.distanceTo(pointB), side.roofFace);
  }

  private static extendLineByLength(
    A: Vector3,
    B: Vector3,
    extensionlength: number
  ): Vector3 {
    const lenAB = Math.sqrt(
      Math.pow(A.x - B.x, 2.0) + Math.pow(A.y - B.y, 2.0)
    );

    return new Vector3(
      B.x + ((B.x - A.x) / lenAB) * extensionlength,
      B.y + ((B.y - A.y) / lenAB) * extensionlength,
      0
    );
  }
}
