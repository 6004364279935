import MurmurHash3 from "imurmurhash";
import poly2tri from "poly2tri";
import { DoubleSide, Vector3 } from "three";
import { generateTriangulatedSides } from "../services/triangulationService";
import { RoofFace } from "../valueObjects/RoofFace";

export function Module({topMin, topMax, bottomMax, bottomMin, roofFace, moduleHeight}: {topMin: {x: number, y: number, z: number}, topMax: {x: number, y: number, z: number}, bottomMax: {x: number, y: number, z: number}, bottomMin: {x: number, y: number, z: number}, roofFace: RoofFace,moduleHeight: number}) {
    const trianglesVector: number[] = []

    var module = new poly2tri.SweepContext(
        [
            new poly2tri.Point(topMin.x, topMin.y),
            new poly2tri.Point(topMax.x, topMax.y),
            new poly2tri.Point(bottomMax.x, bottomMax.y),
            new poly2tri.Point(bottomMin.x, bottomMin.y),
        ]
      );
    module.triangulate();
    module.getTriangles().forEach((triangle: poly2tri.Triangle) => {
        triangle.getPoints().forEach((point: poly2tri.IPointLike) => {
            trianglesVector.push(point.x, point.y, roofFace.getHeightForPoint(new Vector3(point.x, point.y, 0))+0.001);
        });
    });

    module.getTriangles().forEach((triangle: poly2tri.Triangle) => {
        triangle.getPoints().forEach((point: poly2tri.IPointLike) => {
            trianglesVector.push(point.x, point.y, roofFace.getHeightForPoint(new Vector3(point.x, point.y, 0))+0.001+moduleHeight);
        });
    });

    const topMinVector = new Vector3(topMin.x, topMin.y, );
    topMinVector.setZ(roofFace.getHeightForPoint(topMinVector)+0.001+moduleHeight)
    const topMaxVector = new Vector3(topMax.x, topMax.y, 0);
    topMaxVector.setZ(roofFace.getHeightForPoint(topMaxVector)+0.001+moduleHeight)
    const bottomMaxVector = new Vector3(bottomMax.x, bottomMax.y, 0);
    bottomMaxVector.setZ(roofFace.getHeightForPoint(bottomMaxVector)+0.001+moduleHeight)
    const bottomMinVector = new Vector3(bottomMin.x, bottomMin.y, 0);
    bottomMinVector.setZ(roofFace.getHeightForPoint(bottomMinVector)+0.001+moduleHeight)

    trianglesVector.push(...generateTriangulatedSides(topMinVector, topMaxVector, roofFace.roof.height))
    trianglesVector.push(...generateTriangulatedSides(topMaxVector, bottomMaxVector, roofFace.roof.height))
    trianglesVector.push(...generateTriangulatedSides(bottomMaxVector, bottomMinVector,  roofFace.roof.height))
    trianglesVector.push(...generateTriangulatedSides(bottomMinVector, topMinVector, roofFace.roof.height))

    const triangles = new Float32Array(trianglesVector);
    const murmur = new MurmurHash3()
    return (
        <mesh key={murmur.hash(triangles.toString()).result()}>
          <bufferGeometry
            attach="geometry"
            onUpdate={(self) => self.computeVertexNormals()}
          >
            <bufferAttribute
              attach={"attributes-position"}
              array={triangles}
              itemSize={3}
              count={triangles.length / 3}
            />
          </bufferGeometry>
          <meshPhongMaterial
            color={0x0003bd}
            attach="material"
            side={DoubleSide}
          />
        </mesh>
    );
}