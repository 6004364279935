import React from "react";

export enum ButtonState {
  Primary = "Primary",
  Loading = "Loading",
}

interface ButtonProps {
  readonly buttonState: ButtonState;
  readonly onClick: () => void;
  readonly label: string;
}

export const SpinnerButton: React.FC<ButtonProps> = ({
  buttonState,
  onClick,
  label,
}) => {
  const isLoading = buttonState === ButtonState.Loading;
  return (
    <div className="d-flex justify-content-center mt-5">
      <button onClick={onClick}>
        {isLoading ? 'Loading...' : label}
      </button>
    </div>
  );
};