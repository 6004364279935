import "./App.css";
import { RoofEditor } from "./pages/RoofEditor";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ProjectPage } from "./pages/ProjectPage";
import {
  AppState,
  Auth0Provider,
  User
} from "@auth0/auth0-react";
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

type Auth0ProviderWithRedirectCallbackProps = {
  domain: string;
  clientId: string;
  children?: React.ReactNode;
  redirectUri: string;
}

const Auth0ProviderWithRedirectCallback = ({
  domain,
  clientId,
  children,
}: Auth0ProviderWithRedirectCallbackProps): JSX.Element => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState, user?: User): void => {
    const returnTo =
      appState !== undefined && appState.returnTo !== undefined
        ? appState.returnTo
        : window.location.pathname;

      navigate(returnTo);
  };

  return (
    <>
      <Auth0Provider
        onRedirectCallback={onRedirectCallback}
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        scope='roofPlanner:roofs:read'
      >
        {children}
      </Auth0Provider>
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback domain={process.env.REACT_APP_AUTH0_DOMAIN} clientId={process.env.REACT_APP_AUTH0_CLIENT_ID} redirectUri={window.location.origin}>
        <Routes>
          <Route path="/" element={<ProjectPage />} />
          <Route path="/roof/:id/roofEditor" element={<RoofEditor />} />
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default App;

/**
 * <div style={{ position: 'absolute', top: '0' }}>
      <button
        type="button"
        onClick={() => {
          cameraControls.current?.rotate(DEG45, 0, true);
        }}
      >
        rotate theta 45deg
      </button>
      <button
        type="button"
        onClick={() => {
          cameraControls.current?.reset(true);
        }}
      >
        reset
      </button>
    </div>
 */
